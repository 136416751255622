import React, { Component } from 'react'
import PropTypes from 'prop-types'

class ForumCommentForm extends Component {

  // React setup
  constructor(props) {
    super(props)
    this.state = {
      content: '',
    }
    this.form = React.createRef();
  }

  componentDidMount() {
    this.form.current.addEventListener("keydown", this.handleKeyPress, false)
    window.expandingArea()
  }

  componentWillUnmount() {
    this.form.current.removeEventListener("keydown", this.handleKeyPress, false)
  }

  handleChange = (e) => {
    const name = e.target.name
    this.setState({ [`${name}`]: e.target.value })
  }

  handleSubmit = (e) => {
    e.preventDefault()
    if (this.state.content.length !== 0 && this.props.connected)
      this.props.handleNewComment(this.state.content)
  }

  handleKeyPress = (e) => {
    if (e.key == "Enter" && !e.shiftKey && this.props.submitOnEnter) { // enter
      this.handleSubmit(e)
    }
    if (e.key == "Escape") { // esc
      this.props.handleCancel(e)
    }
  }

  render() {

    const modTag =
      <small className="ml-1">
        <span
          className="badge badge-info"
          // data-delay={{'show': 250, 'hide': 0}}
          data-placement="right"
          data-toggle="tooltip"
          data-trigger="hover"
          title="Forum moderator"
        >
          MOD
      </span>
      </small>

    return (
      <div className={`media ${this.props.reply ? 'reply' : null}`}>
        <div className="mr-2">
          <div
            className="color-box"
            style={{ backgroundColor: this.props.user.profile_color }}
          >
            <span className="initials">{this.props.user.initials}</span>
          </div>
        </div>
        <div className="media-body">
          <a
            href="#"
            onClick={this.props.handleCancel}
          >
            <i className="fa-solid fa-xmark float-right text-muted"></i>
          </a>
          <h4 className="media-heading">
            {this.props.user.display_name}
            {this.props.user.can_moderate_forum ? modTag : null}
          </h4>
          <form
            ref={this.form}
            onSubmit={this.handleSubmit}
          >
            <div className="form-group">
              <div className="expanding-area">
                <pre>
                  <span></span>
                  <br />
                </pre>
                <textarea
                  autoFocus="autofocus"
                  className="form-control"
                  name="content"
                  onChange={this.handleChange}
                  placeholder="Type your comment"
                  value={this.state.content}
                >

                </textarea>
              </div>
            </div>
            <button
              type="submit"
              className="btn btn-primary btn-block"
              disabled={this.state.content.length == 0 || !this.props.connected}
            >
              {!this.props.connected ? "Disconnected" : this.props.reply ? "Reply" : "Add Comment"}
            </button>
          </form>
          <p className="d-flex justify-content-between">
            <span>
              <a
                href="#"
                onClick={this.props.handleCancel}
              >
                Cancel
              </a>
            </span>
            <span>
              <input
                className="form-check-input"
                type="checkbox"
                name="submitOnEnter"
                id="submitOnEnter"
                checked={this.props.submitOnEnter}
                onChange={this.props.handleSubmitOnEnterChange}
              />
              <label className="form-check-label" htmlFor="submitOnEnter">
                Submit with Enter
              </label>
            </span>
          </p>
        </div>
      </div>
    )
  }
}

ForumCommentForm.defaultProps = {
  user: {},
  handleNewComment: () => { },
  handleCancel: () => { },
  connected: false,
  reply: false,
  submitOnEnter: true,
  handleSubmitOnEnterChange: () => { },
}

ForumCommentForm.propTypes = {
  user: PropTypes.object,
  handleNewComment: PropTypes.func,
  handleCancel: PropTypes.func,
  connected: PropTypes.bool,
  reply: PropTypes.bool,
  submitOnEnter: PropTypes.bool,
  handleSubmitOnEnterChange: PropTypes.func,
}

export default ForumCommentForm
