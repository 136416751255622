import React, { Component } from 'react'
import PropTypes from 'prop-types'
import ForumReportReason from './ForumReportReason'
import ax from 'utilities/axios'

class ForumReportSubmission extends Component {

  // React setup
  constructor(props) {
    super(props)
    this.state = {
      reasonsLoaded: false,
      reportSubmitted: false,
      reportFailedReason: '',
      reportReasons: [],
    }
  }

  componentDidMount() {
    ax.get("/forum/report_reasons.json")
      .then(data => {
        this.setState({
          reportReasons: data.data,
          reasonsLoaded: true,
        })
      })

    switch (this.props.reportableType) {
      case "User":
        this.setState({ reportableFriendlyName: "user" })
        break;

      case "ForumPost":
        this.setState({ reportableFriendlyName: "post" })
        break;

      case "ForumComment":
        this.setState({ reportableFriendlyName: "comment" })
        break;

    }

  }

  cancelReport = (e) => {
    e.preventDefault()
    this.props.cancelReport(e)
  }

  handleReport = (reasonId) => {
    ax.post("/forum/reports", {
      reportable_type: this.props.reportableType,
      reportable_id: this.props.reportableId,
      forum_report_reason_id: reasonId,
      reported_by_id: gon.user.id,
    })
      .then(data => {
        this.setState({ reportSubmitted: true })
      })
      .catch(error => {
        if (error.response.data.reported_by_id && String(error.response.data.reported_by_id) == String("already reported this item")) {
          this.setState({ reportFailedReason: 'already_reported' })
        } else {
          this.setState({ reportFailedReason: 'unknown' })
        }
      })
  }

  render() {
    const submissionForm =
      <>
        <a
          href="#"
          className="cancel text-muted"
          key="cancel"
          onClick={this.cancelReport}
        >
          <i className="fa-solid fa-xmark"></i>
        </a>
        <h4 key="heading">Why are you reporting this {this.state.reportableFriendlyName}?</h4>
        <p key="reasons">
          {this.state.reportReasons.map(reason => (
            <ForumReportReason
              key={reason.id}
              reason={reason}
              handleReport={this.handleReport}
            />
          ))}
        </p>
        <p
          key="description"
          className="text-muted"
        >
          <em>This item will be reported to the moderators for review.</em>
        </p>
      </>

    return (
      <div className="forum-report-submission">
        {this.state.reasonsLoaded && !this.state.reportSubmitted && !this.state.reportFailedReason ? submissionForm : null}
        {this.state.reportSubmitted ? <h4 className="text-muted">This {this.state.reportableFriendlyName} has been reported to the moderators for review.</h4> : null}
        {this.state.reportFailedReason && this.state.reportFailedReason == "already_reported" ?
          <h4 className="text-muted">You've already reported this {this.state.reportableFriendlyName}.</h4>
          : this.state.reportFailedReason && this.state.reportFailedReason == "unknown" ?
            <h4 className="text-muted">The report failed. Please try again later.</h4>
            : null}
      </div>
    )
  }
}

ForumReportSubmission.defaultProps = {
  reportableType: {},
  reportableId: 0,
  cancelReport: () => { },
}

ForumReportSubmission.propTypes = {
  reportableType: PropTypes.string,
  reportableId: PropTypes.number,
  cancelReport: PropTypes.func,
}

export default ForumReportSubmission
