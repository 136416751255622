import React, { Component } from 'react'
import PropTypes from 'prop-types'
import ShowStaffMember from "./ShowStaffMember";
import ShowStaffMemberForm from "./ShowStaffMemberForm";

class ShowStaffMembers extends Component {

  // React setup
  constructor(props) {
    super(props)
    this.state = {
      showNewShowStaffMemberForm: false,
      showStaffMembers: []
    }
  }

  componentDidMount() {
    this.setState({ showStaffMembers: this.sortStaffMembers(this.props.show_staff_members) })
    this.setupSubscription()
  }

  componentWillUnmount() {
    this.removeSubscription()
  }

  sortStaffMembers = (showStaffMembers) => {
    return _.sortBy(showStaffMembers, (showStaffMember) => [showStaffMember.role, showStaffMember.staff_member.last_name, showStaffMember.staff_member.first_name])
  }

  // handlers
  toggleNewShowStaffMemberForm = (e) => {
    e.preventDefault()
    this.setState({ showNewShowStaffMemberForm: !this.state.showNewShowStaffMemberForm })
  }

  updateShowStaffMemberList = (data) => {
    data = JSON.parse(data.show_staff_members)
    const showStaffMembers = this.sortStaffMembers(data)
    this.setState({ showStaffMembers: showStaffMembers })
  }

  setupSubscription = () => {
    const _this = this
    App['show:' + this.props.show_id + ':show_staff_members'] = App.cable.subscriptions.create({
      channel: "ShowStaffMembersChannel",
      show_id: this.props.show_id,
    }, {
        received: (data) =>
          _this.updateShowStaffMemberList(data)
      })
  }

  removeSubscription = () => {
    if (App['show:' + this.props.show_id + ':show_staff_members'])
      App.cable.subscriptions.remove(App['show:' + this.props.show_id + ':show_staff_members'])
  }

  // CRUD handlers
  // updateShowStaffMember = (comment, data) => {
  //   const index = this.state.showStaffMembers.indexOf(show_staff_member)
  //   const showStaffMembers = React.addons.update(this.state.showStaffMembers, { $splice: [[index, 1, data]] })
  //   this.setState({ showStaffMembers: _.sortBy(showStaffMembers, ['role', 'show_staff_member.staff_member.last_name', 'show_staff_member.staff_member.first_name']) })
  // }

  render() {
    const formSection =
      this.state.showNewShowStaffMemberForm ?
        <ShowStaffMemberForm
          band_slug={this.props.band_slug}
          show_slug={this.props.show_slug}
          handleToggleNewShowStaffMemberForm={this.toggleNewShowStaffMemberForm}
        />
        : <button
          className="btn btn-primary btn-block mb-3"
          onClick={this.toggleNewShowStaffMemberForm}
        >
          <i className="fa-solid fa-plus mr-1"></i>
          Add Staff Member
        </button>

    const staffTable =
      <table className="table table-sm">
        <tbody>
          {this.state.showStaffMembers.map(show_staff_member => (
            <ShowStaffMember
              key={show_staff_member.id}
              show_staff_member={show_staff_member}
              band_slug={this.props.band_slug}
              show_slug={this.props.show_slug}
              handleEditShowStaffMember={this.updateShowStaffMember}
            />
          ))}
        </tbody>
      </table>

    return (
      <>
        {typeof gon != 'undefined' && gon.user.can_edit_bands ? formSection : null}

        {this.state.showStaffMembers.length > 0 ? staffTable : null}
      </>
    )
  }
}

ShowStaffMembers.defaultProps = {
  show_staff_members: [],
  band_slug: "",
  show_slug: "",
  show_id: 0,
}

ShowStaffMembers.propTypes = {
  show_staff_members: PropTypes.arrayOf(PropTypes.object),
  band_slug: PropTypes.string,
  show_slug: PropTypes.string,
  show_id: PropTypes.number,
}

export default ShowStaffMembers
