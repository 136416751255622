import React, { Component } from 'react'
import PropTypes from 'prop-types'

class UserLink extends Component {

  render() {
    return (
      <sup className="ml-1">
        <a
          href={this.props.user_url}
          target="_blank"
        >
          <i className="fa-solid fa-arrow-up-right-from-square"></i>
        </a>
      </sup>
    )
  }
}

UserLink.defaultProps = {
  user_url: "",
}

UserLink.propTypes = {
  user_url: PropTypes.string,
}

export default UserLink
