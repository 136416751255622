import React, { Component } from 'react'
import PropTypes from 'prop-types'

class ForumReportReason extends Component {

  // React setup
  constructor(props) {
    super(props)
    this.state = {
      reasonsLoaded: false,
      reportReasons: [],
    }
  }

  handleReport = (e) => {
    e.preventDefault()
    this.props.handleReport(this.props.reason.id)
  }

  render() {
    return (
      <a
        href="#"
        onClick={this.handleReport}
        className="btn btn-primary-o mr-1 mb-1"
      >
        {this.props.reason.reason}
      </a>
    )
  }
}

ForumReportReason.defaultProps = {
  reason: {},
  handleReport: () => { },
}

ForumReportReason.propTypes = {
  reason: PropTypes.object,
  handleReport: PropTypes.func,
}

export default ForumReportReason
