import React, { Component } from 'react'
// import PropTypes from 'prop-types'
import SearchSuggestion from './SearchSuggestion'
import ax from 'utilities/axios'

class Search extends Component {

  // React setup
  constructor(props) {
    super(props)
    this.state = {
      query: '',
      bandsLoaded: false,
      showsLoaded: false,
      contestsLoaded: false,
      staffMembersLoaded: false,
      bandResults: [],
      showResults: [],
      contestResults: [],
      staffMemberResults: [],
    }
  }

  componentDidMount() {
    document.addEventListener("keydown", this.handleKeyPress, false)
    this.executeSearch = _.debounce(this.executeSearch, 300)
    this.logSearch = _.debounce(this.logSearch, 1300)
  }

  componentWillUnmount() {
    document.removeEventListener("keydown", this.handleKeyPress, false)
  }

  searchInProgress = () => {
    return this.state.query &&
      this.state.query.length > 1 &&
      (
        !this.state.bandsLoaded ||
        !this.state.showsLoaded ||
        !this.state.contestsLoaded ||
        !this.state.staffMembersLoaded
      )
  }

  noResults = () => {
    return this.state.bandsLoaded &&
      this.state.showsLoaded &&
      this.state.contestsLoaded &&
      this.state.staffMembersLoaded &&
      this.state.bandResults.length === 0 &&
      this.state.showResults.length === 0 &&
      this.state.contestResults.length === 0 &&
      this.state.staffMemberResults.length === 0
  }

  clearResults = () => {
    this.executeSearch.cancel()
    this.setState({
      bandResults: [],
      showResults: [],
      contestResults: [],
      staffMemberResults: [],
      bandsLoaded: false,
      showsLoaded: false,
      contestsLoaded: false,
      staffMembersLoaded: false,
    })
  }

  logSearch = () => {
    // log search query in Google Analytics
    if (typeof ga != 'undefined') ga('send', 'pageview', `/ga/search?q=${this.state.query}`)
    if (typeof gtag != 'undefined') gtag('event', 'page_view', { page_path: `/ga/search?q=${this.state.query}` })
  }

  executeSearch = () => {
    this.setState({
      bandsLoaded: false,
      showsLoaded: false,
      contestsLoaded: false,
      staffMembersLoaded: false,
    })

    this.logSearch()

    // query bands
    ax.get(`/bands.json?q=${this.state.query}`)
      .then((data) => {
        this.setState({
          bandResults: data.data,
          bandsLoaded: true
        })
      })

    // query shows
    ax.get(`/shows.json?q=${this.state.query}`)
      .then((data) => {
        this.setState({
          showResults: data.data,
          showsLoaded: true
        })
      })

    // query contests
    ax.get(`/contests.json?q=${this.state.query}`)
      .then((data) => {
        this.setState({
          contestResults: data.data,
          contestsLoaded: true
        })
      })

    // query staff members
    ax.get(`/staff_members.json?q=${this.state.query}`)
      .then((data) => {
        this.setState({
          staffMemberResults: data.data,
          staffMembersLoaded: true
        })
      })

  }

  handleClose = (e) => {
    this.props.toggleSearch(e)
  }

  handleInputChange = (e) => {
    const inputLength = e.target.value.length
    this.setState({ query: e.target.value })

    if (inputLength < 2) {
      this.clearResults()
    }
    if (this.state.query && inputLength > 1) {
      this.executeSearch()
    }
  }

  handleKeyPress = (e) => {
    if (e.keyCode == 13) { // enter
      e.preventDefault()
      this.executeSearch()

      // log search action in Google Analytics
      if (typeof ga != 'undefined') ga('send', 'event', 'search', 'Enter: Execute search', this.state.query)
      if (typeof gtag != 'undefined') gtag('event', 'Enter: Execute search', { 'event_category': 'search', 'event_label': this.state.query } )

    } else if (e.keyCode == 27) { // esc
      e.preventDefault()
      if (this.state.query.length > 0) {
        this.setState({ query: '' })
        this.clearResults()

        // log search action in Google Analytics
        if (typeof ga != 'undefined') ga('send', 'event', 'search', 'Esc: Clear search', this.state.query)
        if (typeof gtag != 'undefined') gtag('event', 'Esc: Clear search', { 'event_category': 'search', 'event_label': this.state.query } )
      } else {
        this.props.toggleSearch(e)

        // log search action in Google Analytics
        if (typeof ga != 'undefined') ga('send', 'event', 'search', 'Esc: Close search')
        if (typeof gtag != 'undefined') gtag('event', 'Esc: Close search', { 'event_category': 'search', 'event_label': this.state.query } )
      }
    } else if (e.keyCode == 38) {// up arrow
      e.preventDefault()
    } else if (e.keyCode == 40) {// down arrow
      e.preventDefault()
    }

  }

  render() {
    return (
      <div className="site-search">
        <div className="search-close">
          <i
            className="fa-solid fa-xmark"
            onClick={this.handleClose}
          >
          </i>
        </div>

        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="search-field">
                <form>
                  <input
                    autoCapitalize="off"
                    autoFocus="autofocus"
                    placeholder="Search..."
                    onChange={this.handleInputChange}
                    value={this.state.query}
                    spellCheck="off"
                  />
                </form>

                {/* search progress indicator */}
                {this.searchInProgress() ? <div className="search-loading"><i className="fa-solid fa-spinner fa-spin fa-fw"></i></div> : null}

              </div>

              {/* no results indicator */}
              {this.noResults() ? <p className="no-results">No results…</p> : null}

              {/* search results */}
              <ul className="list-unstyled">
                {this.state.bandResults.map((result) => (
                  <SearchSuggestion
                    key={`band_${result.id}`}
                    title={result.name}
                    url={result.html_url}
                    query={this.state.query}
                  />
                ))}

                {this.state.showResults.map((result) => (
                  <SearchSuggestion
                    key={`show_${result.id}`}
                    title={result.name}
                    subtitle={result.band_name}
                    year={result.year}
                    url={result.html_url}
                    query={this.state.query}
                  />
                ))}

                {this.state.contestResults.map((result) => (
                  <SearchSuggestion
                    key={`contest_${result.id}`}
                    title={result.name}
                    year={result.year}
                    url={result.html_url}
                    query={this.state.query}
                  />
                ))}

                {this.state.staffMemberResults.map((result) => (
                  <SearchSuggestion
                    key={`staff_member_${result.id}`}
                    title={`${result.first_name} ${result.last_name}`}
                    url={result.html_url}
                    query={this.state.query}
                  />
                ))}
              </ul>
            </div>
          </div>
        </div>
      </div>
    )
  }
}
export default Search
