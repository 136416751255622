import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Select from 'react-select'
import ax from 'utilities/axios'

class ShowPerformanceForm extends Component {

  // React setup
  constructor(props) {
    super(props)
    this.state = {
      performanceAddError: false,
      contest_id: 0,
      showForm: false,
      submitting: false,
    }
  }

  customFilter(option, searchText) {
    return (
      option.data.name.toLowerCase().includes(searchText.toLowerCase()) ||
      option.data.location.toLowerCase().includes(searchText.toLowerCase()) ||
      option.data.venue_city.toLowerCase().includes(searchText.toLowerCase())
    )
  }

  // handlers
  handleSelect = (val) => {
    this.setState({ contest_id: val.id })
  }

  toggleForm = (e) => {
    e.preventDefault()
    this.setState((state, props) => ({
      showForm: !state.showForm
    }))
  }

  handleSubmit = (e) => {
    e.preventDefault()
    this.setState({
      performanceAddError: false,
      submitting: true,
    })
    ax.post(`/contests/${this.state.contest_id}/performances`,
      {
        performance: {
          show_id: this.props.show_id
        },
      }
    )
      .then(data => {
        // this.setState({
        //   contest_id: '',
        //   performanceAddError: false,
        //   submitting: false,
        // })
        Turbolinks.visit(location.toString())
      })
      .catch(error => {
        this.setState({
          performanceAddError: true,
          submitting: false,
        })
      })
  }


  render() {

    const addButton =
      <button
        className="btn btn-primary btn-block mb-3"
        onClick={this.toggleForm}
      >
        <i className="fa-solid fa-plus mr-1"></i>
        Add Performance
      </button>

    const performanceForm =
      <form
        ref={this.form}
        onSubmit={this.handleSubmit}
      >
        <div className="form-group">
          <Select
            options={this.props.contests}
            getOptionLabel={option => `${moment(option.date).format("MMM D")} - ${option.name}`}
            getOptionValue={option => option['id']}
            filterOption={this.customFilter}
            onChange={this.handleSelect}
            autoFocus={true}
          />

        </div>
        {this.state.performanceAddError ? <div className="alert alert-danger" role="alert">An error occurred. Please try again.</div> : null}
        <button
          type="submit"
          className="btn btn-primary btn-block"
          disabled={this.state.contest_id === 0 || this.state.submitting}
        >
          {this.state.submitting ? <i className="fa-solid fa-spinner fa-spin mr-1"></i> : null}
          Add Performance
        </button>
        <button
          className="btn btn-link btn-block"
          onClick={this.toggleForm}
        >
          Cancel
        </button>
      </form>

    return (
      this.state.showForm ? performanceForm : addButton
    )
  }
}

ShowPerformanceForm.defaultProps = {
  contests: [],
  show_id: '',
}

ShowPerformanceForm.propTypes = {
  contests: PropTypes.arrayOf(PropTypes.object),
  show_id: PropTypes.number,
}

export default ShowPerformanceForm
