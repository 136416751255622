import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import ax from 'utilities/axios'

class ResourceLink extends Component {

  // React setup
  constructor(props) {
    super(props)
    this.state = {
      showOptions: false,
      removing: false,
    }
  }

  // handlers
  toggleOptions = (e) => {
    if (typeof gon != 'undefined' && ((this.props.linkable_type == "band" && gon.user.can_edit_bands) || (this.props.linkable_type == "contest" && gon.user.can_edit_contests))) {
      e.preventDefault()
      this.setState({ showOptions: !this.state.showOptions })
    }
  }

  removeResourceLink = (e) => {
    e.preventDefault()
    this.setState({ removing: true })
    ax.delete(this.props.resource_link.url)
      .catch(error => {
        alert("An error occurred while removing this link. Please try again later.")
        this.setState({ removing: false })
      })
  }

  render() {
    const changeLogLink =
      <a
        href={this.props.resource_link.html_url}

      >
        View Change History
      </a>

    const optionsToolbar =
      <div
        className="btn-toolbar d-flex"
        role="toolbar"
        aria-label="Link actions"
      >
        <div className="btn-group btn-group-sm flex-fill mr-1">
          {typeof gon != 'undefined' && gon.user.can_view_change_log ? changeLogLink : null}
        </div>

        <a
          href={`${this.props.resource_link.html_url}/edit`}
          className="btn btn-warning btn-sm mr-1 flex-fill">
          <i className="fa-solid fa-pencil mr-1"></i>
          Edit
          </a>

        <button
          onClick={this.removeResourceLink}
          className="btn btn-danger btn-sm mr-1 flex-fill"
          disabled={this.state.removing}
          data-confirm="Are you sure you want to remove this link?"
        >
          {this.state.removing ? <i className="fa-solid fa-spinner fa-spin mr-1"></i> : null}
          <i className="fa-solid fa-trash mr-1"></i>
          Remove
        </button>

        <button
          onClick={this.toggleOptions}
          className="btn btn-secondary btn-sm mr-1 flex-fill"
        >
          <i className="fa-solid fa-xmark mr-1"></i>
          Close
        </button>
      </div>


    const resourceLinkButton =
      <a
        href={this.props.resource_link.destination_url}
        target="_blank"
        className="btn btn-primary btn-block d-flex flex-column align-items-center"
        onClick={this.toggleOptions}
      >
        <span className="font-weight-bold">{this.props.resource_link.name}</span>
        <small className="font-weight-light">{_.trunc(this.props.resource_link.destination_url_domain, { length: 32 })}</small>
      </a>


    return (
      <>
        {this.state.showOptions ? optionsToolbar : resourceLinkButton}
      </>
    )
  }
}

ResourceLink.defaultProps = {
  resource_link: {},
  linkable_type: "",
  linkable_id: 0,
}

ResourceLink.propTypes = {
  resource_link: PropTypes.object,
  linkable_type: PropTypes.string,
  linkable_id: PropTypes.number,
}

export default ResourceLink
