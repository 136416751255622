import React, { Component } from 'react'
import PropTypes from 'prop-types'
import CaptionAward from "./CaptionAward";
import CaptionAwardForm from "./CaptionAwardForm";

class CaptionAwards extends Component {

  // React setup
  constructor(props) {
    super(props)
    this.state = {
      showNewCaptionAwardForm: false,
      captionAwards: []
    }
  }

  componentDidMount() {
    this.setState({ captionAwards: this.props.caption_awards })
  }

  // handlers
  toggleNewCaptionAwardForm = (e) => {
    e.preventDefault()
    this.setState({ showNewCaptionAwardForm: !this.state.showNewCaptionAwardForm })
  }

  handleNewCaptionAward = (captionAward) => {
    const captionAwards = this.state.captionAwards.concat([captionAward])
    this.setState({ captionAwards: captionAwards })
  }

  handleRemoveCaptionAward = (id) => {
    const captionAwards = this.state.captionAwards.filter(captionAward => captionAward.id !== id);
    this.setState({ captionAwards: captionAwards });
  }

  updateCaptionAwardList = (data) => {
    data = JSON.parse(data.caption_awards)
    this.setState({ captionAwards: data })
  }

  // CRUD handlers
  // updateCaptionAward = (comment, data) => {
  //   const index = this.state.captionAwards.indexOf(caption_award)
  //   const captionAwards = React.addons.update(this.state.captionAwards, { $splice: [[index, 1, data]] })
  //   this.setState({ captionAwards: _.sortBy(captionAwards, ['role', 'caption_award.staff_member.last_name', 'caption_award.staff_member.first_name']) })
  // }

  render() {
    const formSection =
      this.state.showNewCaptionAwardForm ?
        <CaptionAwardForm
          contest_slug={this.props.contest_slug}
          handleToggleNewCaptionAwardForm={this.toggleNewCaptionAwardForm}
          handleNewCaptionAward={this.handleNewCaptionAward}
        />
        : <button
          className="btn btn-primary btn-block"
          onClick={this.toggleNewCaptionAwardForm}
        >
          <i className="fa-solid fa-plus mr-1"></i>
          Add Caption Award
        </button>

    return (
      <div className="card shadow-sm">
        <h3 className="card-header">
          Caption Awards
          <i
            className="fa-solid fa-eye-slash text-black-50 ml-1"
            // data-delay={{ "show": 250, "hide": 0 }}
            data-toggle="tooltip"
            data-trigger="hover"
            title="Hidden from public"
          ></i>
        </h3>

        <div className="card-body">{formSection}</div>

        <ul className="list-group list-group-flush">
          {this.state.captionAwards.map(caption_award => (
            <CaptionAward
              key={caption_award.id}
              caption_award={caption_award}
              contest_slug={this.props.contest_slug}
              handleEditCaptionAward={this.updateCaptionAward}
              handleRemoveCaptionAward={this.handleRemoveCaptionAward}
              />
          ))}
        </ul>

      </div>
    )
  }
}

CaptionAwards.defaultProps = {
  caption_awards: [],
  contest_slug: "",
}

CaptionAwards.propTypes = {
  caption_awards: PropTypes.arrayOf(PropTypes.object),
  contest_slug: PropTypes.string,
}

export default CaptionAwards
