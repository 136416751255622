import React, { Component, Fragment } from 'react'
// import PropTypes from 'prop-types'
import Search from './Search'
import ForumNotifications from './ForumNotifications'

class Navbar extends Component {

  // React setup
  constructor(props) {
    super(props)
    this.state = {
      showSearch: false
    }
  }

  componentDidMount() {
    document.addEventListener("keydown", this.handleKeyPress, false)

    this.handleNavbarCollapse()
    this.handleScroll()
  }

  componentWillUnmount() {
    document.removeEventListener("keydown", this.handleKeyPress, false)

    $("#inbandsNavbarCollapse").off('hide.bs.collapse')
    $("#inbandsNavbarCollapse").off('show.bs.collapse')

  }

  toggleSearch = () => {
    this.setState({ showSearch: !this.state.showSearch })
    if (this.state.showSearch) {
      $("body").addClass("prevent-scroll")
    } else {
      $("body").removeClass("prevent-scroll")
    }
  }

  handleKeyPress = (e) => {
    if (!$(e.target).is("input") && !$(e.target).is("textarea") && e.keyCode == 191) { // forward slash
      e.preventDefault()
      if (typeof ga != 'undefined') ga('send', 'event', 'ui', 'Slash: Open search', window.location.pathname)
      if (typeof gtag != 'undefined') gtag('event', 'Slash: Open search', { 'event_category': 'ui', 'event_label': window.location.pathname } )
      this.toggleSearch()
    }
  }

  handleSearchClick = (e) => {
    e.preventDefault()
    if (typeof ga != 'undefined') ga('send', 'event', 'ui', 'Click: Open search', window.location.pathname)
    if (typeof gtag != 'undefined') gtag('event', 'Click: Open search', { 'event_category': 'ui', 'event_label': window.location.pathname } )
    this.toggleSearch()
  }

  handleScroll = (e) => {
    const offset = document.querySelector('header').offsetHeight - 55

    document.addEventListener('scroll', () => {
      if ($(window).scrollTop() > offset || (document.getElementsByClassName("navbar-collapse")[0] && document.getElementsByClassName("navbar-collapse")[0].classList.contains("show"))) {
        $("nav.navbar").addClass("bg-dark")
        $("nav.navbar").removeClass("bg-translucent")
      } else {
        $("nav.navbar").addClass("bg-translucent")
        $("nav.navbar").removeClass("bg-dark")
      }
    })
  }

  handleNavbarCollapse = () => {
    const offset = document.querySelector('header').offsetHeight - 55

    $("#inbandsNavbarCollapse").on('hide.bs.collapse', () => {
      if ($(window).scrollTop() <= offset) {
        $("nav.navbar").addClass("bg-translucent")
        $("nav.navbar").removeClass("bg-dark")
      }
  })
    $("#inbandsNavbarCollapse").on('show.bs.collapse', () => {
      $("nav.navbar").addClass("bg-dark")
      $("nav.navbar").removeClass("bg-translucent")
    })
  }

  render() {
    const loggedInUserMenu =
      <div className='nav-item dropdown'>
        <a
          className='nav-link dropdown-toggle'
          href='#'
          data-toggle='dropdown'
          aria-haspopup='true'
          aria-expanded='false'
        >
          <i className='fa-solid fa-user'></i>
          <span className='d-inline d-md-none'>User Profile</span>
        </a>

        <div className='dropdown-menu dropdown-menu-right'>
          <a
            className='dropdown-item'
            href='/auth/edit'
          >
            <i className='fa-solid fa-pencil mr-1'></i>
            Edit Profile
          </a>

          {typeof gon != 'undefined' && (gon.user.can_edit_submissions || gon.user.can_moderate_forum || gon.user.can_edit_users || gon.user.can_edit_circuits) ?
            <Fragment>
              <div className='dropdown-divider'></div>
              <h6 className='dropdown-header'>Administration</h6>
            </Fragment>
          : null}

          {typeof gon != 'undefined' && gon.user.can_edit_submissions ?
            <a
              className='dropdown-item'
              href='/submissions'
            >
              <i className='fa-solid fa-circle-info mr-1'></i>
              Submissions
            </a>
            : null
          }

          {typeof gon != 'undefined' && gon.user.can_moderate_forum ?
            <a
              className='dropdown-item'
              href='/forum/reports'
            >
              <i className='fa-solid fa-comments mr-1'></i>
              Forum Reports
            </a>
            : null
          }

          {typeof gon != 'undefined' && gon.user.can_edit_users ?
            <a
              className='dropdown-item'
              href='/users'
            >
              <i className='fa-solid fa-users mr-1'></i>
              Manage Users
            </a>
            : null
          }

          {typeof gon != 'undefined' && gon.user.can_edit_circuits ?
            <a
              className='dropdown-item'
              href='/circuits'
            >
              <i className='fa-solid fa-wrench mr-1'></i>
              Manage Circuits
            </a>
            : null
          }

          <div className='dropdown-divider'></div>

          <a
            className='dropdown-item'
            href='/auth/sign_out'
            data-method='delete'
            data-turbo_method='delete'
            rel='nofollow'
          >
            <i className='fa-solid fa-right-from-bracket mr-1'></i>
            Sign Out
          </a>
        </div>
      </div>

    const loggedOutUserMenu =
      <a
        className='nav-item nav-link'
        href='/auth/sign_in'
      >
        Sign In
      </a>


    return (
      <>

        {this.state.showSearch ? <Search toggleSearch={this.handleSearchClick} /> : null}

        <nav className="navbar fixed-top navbar-expand-md navbar-dark bg-translucent">
          <div className='container'>
            <a className='navbar-brand' href='/'>
              IN Bands
            </a>

            <button
              className='navbar-toggler'
              type='button'
              onClick={this.handleSearchClick}
            >
              <i className='fa-solid fa-magnifying-glass' />
            </button>

            <button
              className='navbar-toggler'
              type='button'
              data-toggle='collapse'
              data-target='#inbandsNavbarCollapse'
              aria-controls='inbandsNavbarCollapse'
              aria-expanded='false'
              aria-label='Toggle navigation'
            >
              <span className='navbar-toggler-icon' />
            </button>

            <div
              className='collapse navbar-collapse'
              id='inbandsNavbarCollapse'
            >

              {/* main navigation links */}
              <div className='navbar-nav mr-auto'>

                <a
                  className='nav-item nav-link'
                  href='/bands'
                >
                  Bands
                </a>

                <a
                  className='nav-item nav-link'
                  href='/contests'
                >
                  Contests
                </a>

                <a
                  className='nav-item nav-link'
                  href='/forum'
                  rel='nofolow'
                >
                  Forum
                </a>
              </div>

              {/* right navigation */}
              <div className='navbar-nav ml-auto'>

                <a
                  className='nav-item nav-link btn btn-primary text-white'
                  href='/submit-information'
                >
                  Submit Information
                </a>

                <a
                  className='nav-item nav-link ml-sm-1'
                  href='https://facebook.com/INBands'
                  target='_blank'
                >
                  <i className='fa-brands fa-facebook'></i>
                  {" "}
                  <span className="d-sm-none">Facebook</span>
                </a>

                <a
                  className='nav-item nav-link'
                  href='https://instagram.com/in_bands'
                  target='_blank'
                >
                  <i className='fa-brands fa-instagram'></i>
                  {" "}
                  <span className="d-sm-none">Instagram</span>
                </a>

                <a
                  className='nav-item nav-link d-none d-md-block ml-2'
                  href='#'
                  onClick={this.handleSearchClick}
                >
                  <i className='fa-solid fa-magnifying-glass'></i>
                </a>

                {(typeof gon != 'undefined' && typeof gon.user != 'undefined') ? <ForumNotifications /> : null}

                {(typeof gon != 'undefined' && typeof gon.user != 'undefined') ? loggedInUserMenu : loggedOutUserMenu}

              </div>
            </div>
          </div>
        </nav>
      </>
    )
  }
}

export default Navbar
