import React, { Component } from 'react'
// import PropTypes from 'prop-types'
import ax from 'utilities/axios'
import ForumNotification from './ForumNotification'

class ForumNotifications extends Component {

  // React setup
  constructor(props) {
    super(props)
    this.state = {
      forumNotifications: [],
      unreadNotificationsCount: 0,
      loaded: false,
      error: false,
    }
  }

  componentDidMount() {
    this.setupSubscription()

    ax.get("/forum/notifications.json")
      .then((data) => {
        this.updateNotificationList(data.data)
        this.setState({ loaded: true })
      })
      .catch((error) => {
        console.error(error);
        this.setState({ error: true })
      })

    $('[data-toggle="tooltip"]').tooltip()
  }

  componentWillUnmount() {
    this.removeSubscription()
  }

  updateNotificationList = (data) => {
    this.setState({ forumNotifications: _.sortBy(data, ['created_at']).reverse() })
    this.setState({ unreadNotificationsCount: _.filter(data, { read: false }).length })
  }

  setupSubscription = () => {
    const _this = this
    App['forum_notifications:' + gon.user.id] = App.cable.subscriptions.create({
      channel: "ForumNotificationsChannel",
    }, {
        received(data) {
          _this.updateNotificationList(JSON.parse(data.forum_notifications))
        },

        markNotificationRead(data) {
          this.perform("mark_notification_read", { forum_notification_id: data.forum_notification_id })
        },

        markAllRead() {
          this.perform("mark_all_read")
        },
      }
    )
  }

  removeSubscription = () => {
    if (typeof App['forum_notifications:' + gon.user.id] != 'undefined')
      App.cable.subscriptions.remove(App['forum_notifications:' + gon.user.id])
  }

  handleMarkAllRead = (e) => {
    e.preventDefault()
    App['forum_notifications:' + gon.user.id].markAllRead()
  }

  render() {
    const markAllReadButton =
      <div className="col text-right">
        <a
          className="rIcon allRead"
          href="#"
          data-toggle="tooltip"
          data-placement="bottom"
          title="Mark all read"
          onClick={this.handleMarkAllRead}
        >
          <i className="fa-regular fa-dot-circle"></i>
        </a>
      </div>

    if (this.state.loaded && this.state.error) {
      var notifications =
        <div className="row">
          <div className="col">
            <p className='no-notifications'>An error occured while loading your notifications</p>
          </div>
        </div>

    } else if (this.state.loaded && this.state.forumNotifications.length > 0) {
      var notifications =
        this.state.forumNotifications.map((notification) => (
          <ForumNotification
            key={notification.id}
            notification={notification}
          />
        ))

    } else if (this.state.loaded) {
      var notifications =
        <div className="row">
          <div className="col">
            <p className='no-notifications'>You have no notifications</p>
          </div>
        </div>
    } else {
      var notifications =
        <div className="row">
          <div className="col">
            <p className='no-notifications'>Loading notifications...</p>
          </div>
        </div>
    }

    return (
      <div className="nav-item dropdown">
        <a
          className="nav-link"
          href="#"
          data-toggle="dropdown"
          role="button"
          aria-haspopup="true"
          aria-expanded="false"
        >
          <i className="fa-solid fa-bell"></i>
          <span className="d-inline d-md-none ml-1">Notifications</span>
          {(this.state.unreadNotificationsCount > 0) ? <span className="badge badge-pill badge-notifications ml-1">{this.state.unreadNotificationsCount}</span> : null}
        </a>

        <div className="dropdown-menu dropdown-menu-right notify-drop">
          <div className="notify-drop-title">
            <div className="row">
              <div className="col">
                {(this.state.unreadNotificationsCount > 0) ? `Notifications (${this.state.unreadNotificationsCount})` : "Notifications"}
              </div>
              {(this.state.unreadNotificationsCount > 0) ? markAllReadButton : null}
            </div>
          </div>

          <div className="drop-content">
            <div className="container">
              {notifications}
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default ForumNotifications
