import React from 'react'
export const ModTag =
  <small className="ml-1">
    <span
      className="badge badge-info"
      // data-delay={{'show': 250, 'hide': 0}}
      data-placement="right"
      data-toggle="tooltip"
      data-trigger="hover"
      title="Forum moderator"
    >
      MOD
        </span>
  </small>

