import React, { Component } from 'react'
import PropTypes from 'prop-types'

class SearchSuggestion extends Component {

  // React setup
  constructor(props) {
    super(props)
  }

  handleResultClick = (e) => {
    e.preventDefault()

    // log search action in Google Analytics
    if (typeof ga != 'undefined') ga('send', 'event', 'search', 'Click: search result', this.props.query)
    if (typeof gtag != 'undefined') gtag('event', 'Click: search result', { 'event_category': 'search', 'event_label': this.props.query } )

    // navigate to selected result
    Turbolinks.visit(this.props.url)
  }

  render(e) {
    return (
      <li onClick={this.handleResultClick}>
        <div className={(this.props.year > 0) ? 'titles' : null}>
          <h3>{this.props.title}</h3>
          {(this.props.subtitle.length > 0) ? <p><em>{this.props.subtitle}</em></p> : null}
        </div>
        {(this.props.year > 0) ? <p className="year">{this.props.year}</p> : null}
      </li>
    )
  }
}

SearchSuggestion.defaultProps = {
  title: '',
  subtitle: '',
  year: 0,
  url: '',
  query: '',
}

SearchSuggestion.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  year: PropTypes.number,
  url: PropTypes.string,
  query: PropTypes.string,
}

export default SearchSuggestion
