import React, { Component } from 'react'
import PropTypes from 'prop-types'
import CreatableSelect from 'react-select/creatable';
import ax from 'utilities/axios'

class ResourceLinkForm extends Component {

  // React setup
  constructor(props) {
    super(props)
    this.state = {
      addError: false,
      name: '',
      destination_url: '',
      role: '',
      submitting: false
    }
    this.nameInput = React.createRef();
    this.form = React.createRef();
  }

  // handlers
  handleChange = (e) => {
    const name = e.target.name
    this.setState({ [`${name}`]: e.target.value })
  }

  handleSelect = (val) => {
    this.setState({ name: val.value })
  }

  handleCancel = (e) => {
    e.preventDefault()
    this.props.handleToggleForm(e)
  }

  handleSubmit = (e) => {
    e.preventDefault()
    this.setState({ submitting: true })
    ax.post(this.props.submission_url,
      {
        resource_link: {
          name: _.trim(this.state.name),
          destination_url: _.trim(this.state.destination_url),
        },
      }
    )
    .then(data => {
      this.nameInput.current.focus()
      this.setState({
        name: '',
        destination_url: '',
        addError: false,
        submitting: false,
      })
    })
    .catch(error => {
      this.setState({
        addError: true,
        submitting: false,
      })
    })
  }

  render() {
    const nameOptions = [
      { value: 'Website', label: 'Website' },
      { value: 'Facebook', label: 'Facebook' },
      { value: 'Instagram', label: 'Instagram' },
      { value: 'Twitter', label: 'Twitter' },
      { value: 'Entry Form', label: 'Entry Form' },
      { value: 'Purchase Tickets', label: 'Purchase Tickets' },
      { value: 'Live Stream', label: 'Live Stream' },
    ]

    return (
      <div className="card">
        <div className="card-body">
          <form
            ref={this.form}
            onSubmit={this.handleSubmit}
          >
            <div className="form-group">
              <CreatableSelect
                options={nameOptions}
                onChange={this.handleSelect}
                autoFocus={true}
                ref={this.nameInput}
              />

            </div>
            <div className="form-group">
              <input
                type="text"
                className="form-control mb-2"
                name="destination_url"
                onChange={this.handleChange}
                placeholder="https://"
                ref={this.destination_url}
                value={this.state.destination_url}
              />
            </div>
            {this.state.addError ? <div className="alert alert-danger" role="alert">An error occurred. Please try again.</div> : null}
            <button
              type="submit"
              className="btn btn-primary btn-block"
              disabled={this.state.name.length === 0 || this.state.submitting}
            >
              {this.state.submitting ? <i className="fa-solid fa-spinner fa-spin mr-1"></i> : null}
              Add Link
            </button>
            <button
              className="btn btn-link btn-block"
              onClick={this.handleCancel}
            >
              Cancel
            </button>
          </form>
        </div>
      </div>
    )
  }
}

ResourceLinkForm.defaultProps = {
  handleToggleForm: () => { },
  submission_url: "",
}

ResourceLinkForm.propTypes = {
  handleToggleForm: PropTypes.func,
  submission_url: PropTypes.string,
}

export default ResourceLinkForm
