import React, { Component } from 'react'
import PropTypes from 'prop-types'

class ForumCommentHideConfirmation extends Component {

  // React setup
  constructor(props) {
    super(props)
    this.state = {
      hiddenSuccessfully: false,
    }
  }

  // handlers
  handleHide = (e) => {
    e.preventDefault()
    App['forum_post:' + gon.forum_post_id].hide_comment({ forum_comment_id: this.props.commentId, hidden: !this.props.hidden })
    this.setState({ hiddenSuccessfully: true })
  }

  cancelHide = (e) => {
    e.preventDefault()
    this.props.cancelHide(e)
  }

  render() {
    const hiddenSuccessfully =
      <h4 className="text-muted">
        This comment has been {this.props.hidden ? "made visible" : "hidden"}.
      </h4>

    const hideConfirmation =
      <>
        <a
          className="cancel text-muted"
          href="#"
          onClick={this.cancelHide}
        >
          <i className="fa-solid fa-xmark"></i>
        </a>
        <h4>Are you sure you want to {this.props.hidden ? "show" : "hide"} this comment?</h4>
        <p>
          <a
            href="#"
            onClick={this.handleHide}
            className="btn btn-danger-o mr-1"
          >
            Yes
          </a>
          <a
            href="#"
            onClick={this.cancelHide}
            className="btn btn-primary-o"
          >
            Cancel
          </a>
        </p>
      </>

    return (
      <div className="forum-report-submission">
        {this.state.hiddenSuccessfully ? hiddenSuccessfully : hideConfirmation}
      </div>
    )
  }
}

ForumCommentHideConfirmation.defaultProps = {
  commentId: 0,
  cancelHide: () => { },
  hidden: false,
}

ForumCommentHideConfirmation.propTypes = {
  commentId: PropTypes.number,
  cancelHide: PropTypes.func,
  hidden: PropTypes.bool,
}

export default ForumCommentHideConfirmation
