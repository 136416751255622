import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { ReactionTypes } from '../ReactionTypes'

class Reactions extends Component {

  render() {
    return (
      ReactionTypes.map(reaction_type => {
        const reactions = _.filter(this.props.reactions, (r) => (r.content === reaction_type.text))
        if (reactions.length > 0) {
          return (
            <p
              className="inline-with-dots"
              key={reaction_type.text}
            >
              <span
                className="badge badge-secondary"
                style={{ backgroundColor: reaction_type.color }}
              >
                <i className={`forum-reaction-icon ${reaction_type.icon} mr-1`}></i>
                {reactions.length}
              </span>
            </p>
          )
        }
      })
    )
  }
}

Reactions.defaultProps = {
  reactions: [],
}

Reactions.propTypes = {
  reactions: PropTypes.arrayOf(PropTypes.object),
}

export default Reactions
