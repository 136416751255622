import React, { Component } from 'react'
import PropTypes from 'prop-types'
import ax from 'utilities/axios'

class ShowStaffMemberForm extends Component {

  // React setup
  constructor(props) {
    super(props)
    this.state = {
      captionAwardAddError: false,
      name: '',
      tie_scope: 'class',
      submitting: false
    }
    this.nameInput = React.createRef();
    this.form = React.createRef();

    this.tie_scope_options = [
      { value: 'class', label: 'Class' },
      { value: 'circuit', label: 'Circuit' },
      { value: 'contest', label: 'Contest' },
      { value: 'none', label: 'None' },
    ]
  }

  componentDidMount() {
    this.form.current.addEventListener("keydown", this.handleKeyPress, false)
  }

  componentWillUnmount() {
    this.form.current.removeEventListener("keydown", this.handleKeyPress, false)
  }

  // handlers
  handleChange = (e) => {
    const name = e.target.name
    this.setState({ [`${name}`]: e.target.value })
  }

  handleOptionChange = changeEvent => {
    this.setState({
      selectedOption: changeEvent.target.value,
    })
  }

  handleKeyPress = (e) => {
    if (e.keyCode == 27) { // esc
      e.preventDefault()
      this.handleCancel(e)
    }
  }

  handleCancel = (e) => {
    e.preventDefault()
    this.props.handleToggleNewCaptionAwardForm(e)
  }

  handleSubmit = (e) => {
    e.preventDefault()
    this.setState({ submitting: true })
    ax.post(`/contests/${this.props.contest_slug}/caption_awards`,
      {
        caption_award: {
          name: this.state.name,
          tie_scope: this.state.tie_scope,
        },
      }
    )
      .then(data => {
        this.props.handleNewCaptionAward(data.data)
        this.nameInput.current.focus()
        this.setState({
          name: '',
          tie_scope: 'class',
          captionAwardAddError: false,
          submitting: false,
        })
      })
      .catch(error => {
        this.setState({
          captionAwardAddError: true,
          submitting: false,
        })
      })
  }

  render() {
    return (
      <div className="card">
        <div className="card-body">
          <form
            ref={this.form}
            onSubmit={this.handleSubmit}
          >
            <div className="form-group">
              <input
                type="text"
                className="form-control mb-2"
                name="name"
                onChange={this.handleChange}
                placeholder="Name"
                ref={this.nameInput}
                autoFocus={true}
                value={this.state.name}
                disabled={this.state.submitting}
              />
            </div>

            <div className="form-group">
              <label
                htmlFor="tie_scope"
                key="label"
              >
                Tie Scope
              </label>
              {this.tie_scope_options.map(level => (
                <div
                  className="form-check"
                  key={level.value}
                  >
                  <input
                    type="radio"
                    className="form-check-input"
                    name="tie_scope"
                    checked={this.state.tie_scope === level.value}
                    onChange={this.handleChange}
                    placeholder="Tie Scope"
                    id={level.value}
                    value={level.value}
                    disabled={this.state.submitting}
                    />
                  <label
                    className="form-check-label"
                    htmlFor={level.value}
                    key={`${level.value}-label`}
                  >
                      {level.label}
                  </label>
                </div>
              ))}
            </div>
            <small
              className="form-text text-muted"
            >
              Can ties occur within each class, circuit, the whole contest, or not at all?
            </small>

            {this.state.captionAwardAddError ? <div className="alert alert-danger" role="alert">An error occurred. Please try again.</div> : null}

            <button
              type="submit"
              className="btn btn-primary btn-block"
              disabled={this.state.name.length === 0 || this.state.submitting}
            >
              {this.state.submitting ? <i className="fa-solid fa-spinner fa-spin mr-1"></i> : null}
              Add Caption Award
            </button>
            <button
              className="btn btn-link btn-block"
              onClick={this.handleCancel}
            >
              Cancel
            </button>
          </form>
        </div>
      </div>
    )
  }
}

ShowStaffMemberForm.defaultProps = {
  contest_slug: '',
  handleToggleNewCaptionAwardForm: () => { },
  handleNewCaptionAward: () => { },
}

ShowStaffMemberForm.propTypes = {
  contest_slug: PropTypes.string,
  handleToggleNewCaptionAwardForm: PropTypes.func,
  handleNewCaptionAward: PropTypes.func,
}

export default ShowStaffMemberForm
