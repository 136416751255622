import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { ReactionTypes } from '../ReactionTypes'
import UserLink from './UserLink'
import Reactions from './Reactions'
import ForumComments from './ForumComments'
import ForumCommentForm from './ForumCommentForm'
import ForumCommentHideConfirmation from './ForumCommentHideConfirmation'
import ForumReportSubmission from './ForumReportSubmission'
import { ModTag } from '../ModTag'

class ForumComment extends Component {

  // React setup
  constructor(props) {
    super(props)
    this.state = {
      replies: [],
      edit: false,
      replyContent: '',
      showReplyForm: false,
      showReportSubmission: false,
      textAreaFocused: false,
    }
  }

  componentDidMount() {
    this.sortReplies(this.props.comment.replies)
    $('[data-toggle="tooltip"]').tooltip()
    if ($(".highlighted-comment").length > 0)
      setTimeout(() => {
        $('body,html').animate({
          scrollTop: $(".highlighted-comment").offset().top - $("nav.navbar").outerHeight() - 5
        }, 0)

      }, 100)
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.comment.replies != this.props.comment.replies)
      this.sortReplies(this.props.comment.replies)

    if (this.state.showReplyForm || this.state.edit)
      window.expandingArea()

  }

  sortReplies = (replies) => {
    replies = _.sortBy(replies, ['created_at'])

    if (!gon.user.can_moderate_forum) {
      replies = _.filter(replies, (c) => (Boolean(c.hidden) === false))
    }

    this.setState({ replies: replies })
  }

  // handlers
  handleReplyChange = (e) => {
    const name = e.target.name
    this.setState({ [`${name}`]: e.target.value })
  }

  addNewReply = (content) => {
    this.setState({ showReplyForm: false })
    App['forum_post:' + gon.forum_post_id].new_comment({ content: content, commentable_type: "ForumComment", commentable_id: this.props.comment.id })
  }

  toggleHide = (e) => {
    e.preventDefault()
    this.setState({ showHideConfirmation: !this.state.showHideConfirmation })
  }

  toggleReport = (e) => {
    e.preventDefault()
    this.setState({ showReportSubmission: !this.state.showReportSubmission })
  }

  handleToggle = (e) => {
    if (e) e.preventDefault()
    this.setState({ edit: !this.state.edit })
    this.setState({ replyContent: this.props.comment.content })
  }

  handleToggleReplyForm = (e) => {
    e.preventDefault()
    this.setState({ showReplyForm: !this.state.showReplyForm })
  }

  handleTextareaFocus = (e) => {
    this.setState({ textAreaFocused: true })
    $(e.target).keyup(ev => {
      if (ev.key == "Enter" && !ev.shiftKey  && this.props.submitOnEnter) { // enter
        ev.preventDefault()
        this.handleEdit()
      }
      if (ev.key == "Escape") { // esc
        ev.preventDefault()
        this.handleToggle()
      }
    })
  }

  handleTextareaBlur = (e) => {
    this.setState({ textAreaFocused: false })
    $(e.target).off("keyup")
  }

  handleReaction = (e) => {
    e.preventDefault()
    App['forum_post:' + gon.forum_post_id].react_comment({ forum_comment_id: this.props.comment.id, reaction: e.target.dataset["reaction"] })
  }

  handleEdit = (e) => {
    if (e) e.preventDefault()
    this.setState({ edit: false })
    App['forum_post:' + gon.forum_post_id].update_comment({ forum_comment_id: this.props.comment.id, content: this.state.replyContent })
  }

  handleDeleteReaction = (e) => {
    e.preventDefault()
    const user_reaction = _.find(this.props.comment.reactions, (r) => (r.user.id === gon.user.id))
    App['forum_post:' + gon.forum_post_id].destroy_comment({ forum_comment_id: user_reaction.id })
  }

  handleDelete = (e) => {
    e.preventDefault()
    swal({
      title: "Are you sure?",
      text: "Do you really want to delete your comment?",
      type: "warning",
      confirmButtonText: "Yes, I'm Sure",
      showCancelButton: true,
    }, () => (
      App['forum_post:' + gon.forum_post_id].destroy_comment({ forum_comment_id: this.props.comment.id })))
  }

  render() {
    const hideOption =
      <a
        href="#"
        className="dropdown-item"
        key="hide"
        onClick={this.toggleHide}
      >
        <i className={`fa-solid ${this.props.comment.hidden ? 'fa-eye' : 'fa-eye-slash'} mr-1`}></i>
        {this.state.showHideConfirmation ? 'Cancel' : ''} {this.props.comment.hidden ? 'Show' : 'Hide'}
      </a>

    const reportOption =
      <a
        href="#"
        className="dropdown-item"
        key="report"
        onClick={this.toggleReport}
      >
        <i className="fa-solid fa-circle-exclamation mr-1"></i>
        {this.state.showReportSubmission ? 'Cancel' : ''} Report
      </a>

    const editOption =
      <a
        href="#"
        className="dropdown-item"
        key="edit"
        onClick={this.handleToggle}
      >
        <i className="fa-solid fa-pencil mr-1"></i>
        Edit
      </a>

    const removeOption =
      <a
        href="#"
        className="dropdown-item"
        key="remove"
        onClick={this.handleDelete}
      >
        <i className="fa-solid fa-trash mr-1"></i>
        Remove
      </a>

    const optionsDropdown =
      <div className="dropdown float-right">
        <a
          href="#"
          className="btn btn-link"
          data-toggle="dropdown"
          aria-haspopup="true"
          aria-expanded="false"
        >
          <i className="fa-solid fa-chevron-down hover-show-icon text-muted"></i>
        </a>
        <div className="dropdown-menu dropdown-menu-right">
          {this.props.comment.user.id != gon.user.id && gon.user.can_moderate_forum ? hideOption : null}
          {this.props.comment.user.id != gon.user.id && !this.props.comment.hidden && gon.user.can_post_in_forum ? reportOption : null}
          {this.props.comment.user.id == gon.user.id ? [editOption, removeOption] : null}
        </div>
      </div>

    const reactionDropdown =
      <div className="inline-with-dots dropup">
        <a
          href="#"
          data-toggle="dropdown"
          aria-haspopup="true"
          aria-expanded="false"
        >
          Like
        </a>
        <div
          className="dropdown-menu"
          key="reactions"
        >
          {ReactionTypes.map(reaction => (
            <a
              className="dropdown-item"
              key={reaction.text}
              href="#"
              data-reaction={reaction.text}
              onClick={this.handleReaction}
            >
              <i className={`forum-reaction-icon-circle ${reaction.icon} mr-1`}></i>
              {reaction.label}
            </a>
          ))}
          {(_.filter(this.props.comment.reactions, (r) => (r.user.id === gon.user.id))).length > 0 ?
            <a
              className="dropdown-item"
              key="unlike"
              href="#"
              onClick={this.handleDeleteReaction}
            >
              <i className="fa-solid fa-xmark text-muted mr-1"></i>
              Unlike
            </a>
            : null
          }
        </div>
      </div>

    const commentRow =
      <div>
        {this.props.comment.content.split('\n').map((line, i) => (
          <span
            key={i}
            className={this.props.comment.hidden ? 'text-muted' : null}
          >
            {line}
            {i != this.props.comment.content.split('\n').length - 1 ? <br /> : null}
          </span>
        ))}
        <br />
        {typeof gon.user != 'undefined' ? reactionDropdown : null}
        {!(this.props.comment['reply?'] || this.props.comment.hidden || this.props.closed) ?
          <div className="inline-with-dots">
            <a
              href="#"
              onClick={this.handleToggleReplyForm}
            >
              Reply
        </a>
          </div>
          : null
        }

        <Reactions reactions={this.props.comment.reactions} />

        <div className="inline-with-dots">
          <span
            className="text-muted"
            data-toggle='tooltip'
            data-trigger='hover'
            title={moment(this.props.comment.created_at).format("dddd, MMM D, YYYY h:mm A")}
          >
            {moment(this.props.comment.created_at).fromNow()}
          </span>
        </div>
        {this.props.comment['edited?'] ?
          <div className="inline-with-dots">
            <span className="text-muted">Edited</span>
          </div>
          : null
        }
        {this.props.comment.hidden ?
          <div className="inline-with-dots">
            <span className="text-muted">Hidden</span>
          </div>
          : null
        }
        {this.state.showHideConfirmation ?
          <ForumCommentHideConfirmation
            commentId={this.props.comment.id}
            cancelHide={this.toggleHide}
            hidden={this.props.comment.hidden}
          />
          : null
        }
        {this.state.showReportSubmission ?
          <ForumReportSubmission
            reportableType="ForumComment"
            reportableId={this.props.comment.id}
            cancelReport={this.toggleReport}
          />
          : null
        }
      </div>

    const commentForm =
      <div>
        <div className="form-group">
          <div className="expanding-area">
            <pre>
              <span></span>
              <br />
            </pre>
            <textarea
              className="form-control"
              autoFocus="autofocus"
              defaultValue={this.state.replyContent}
              name="replyContent"
              onFocus={this.handleTextareaFocus}
              onBlur={this.handleTextareaBlur}
              onChange={this.handleReplyChange}
            ></textarea>
          </div>
        </div>
        <a
          href="#"
          className="btn btn-primary btn-block"
          onClick={this.handleEdit}
          disabled={!(this.state.replyContent.length > 0 && this.props.connected)}
        >
          {this.props.connected ? "Update" : "Disconnected…"}
        </a>
        <p className="d-flex justify-content-between">
          <span>
            <a
              href="#"
              onClick={this.handleToggle}
            >
              Cancel
            </a>
          </span>
          <span>
            <input
              className="form-check-input"
              type="checkbox"
              name="submitOnEnter"
              id="submitOnEnter"
              checked={this.props.submitOnEnter}
              onChange={this.props.handleSubmitOnEnterChange}
            />
            <label className="form-check-label" htmlFor="submitOnEnter">
              Submit with Enter
            </label>
          </span>
        </p>
      </div>

    return (
      <>
        <div className={`media ${this.props.comment['reply?'] ? 'reply' : 'mt-2'} ${this.props.highlightedComment == this.props.comment.id ? 'highlighted-comment' : ''}`}>
          <div className="mr-2">
            <div
              className="color-box"
              style={{ backgroundColor: this.props.comment.user.profile_color }}
            >
              <span className="initials">{this.props.comment.user.initials}</span>
            </div>
          </div>
          <div className="media-body">
            <div className="media-body-block hover-show">

              {!this.state.edit && (this.props.comment.user.id == gon.user.id || (!this.props.comment.hidden && gon.user.can_post_in_forum) || (this.props.comment.user.id != gon.user.id && gon.user.can_moderate_forum)) ? optionsDropdown : null}

              <h4 className={`media-heading ${this.props.comment.hidden ? 'text-muted' : null}`}>

                {this.props.comment.user.display_name}

                {/* show link to user if current_user can_edit_users */}
                {gon.user && gon.user.can_edit_users ? <UserLink user_url={this.props.comment.user.html_url} /> : null}

                {/* show MOD badge for commenting user */}
                {this.props.comment.user.can_moderate_forum ? ModTag : null}
              </h4>

              {this.state.edit ? commentForm : commentRow}

            </div>
          </div>
        </div>
        {!this.props.comment['reply?'] ?
          <ForumComments
            forumComments={this.state.replies}
            connected={this.props.connected}
            closed={this.props.closed}
            highlightedComment={this.props.highlightedComment}
          />
          : null
        }
        {this.state.showReplyForm && !this.props.closed ?
          <ForumCommentForm
            user={gon.user}
            handleNewComment={this.addNewReply}
            handleCancel={this.handleToggleReplyForm}
            reply={true}
            connected={this.props.connected}
            submitOnEnter={this.props.submitOnEnter}
            handleSubmitOnEnterChange={this.props.handleSubmitOnEnterChange}
          />
          : null
        }
      </>
    )
  }
}

ForumComment.defaultProps = {
  comment: {},
  connected: false,
  closed: false,
  highlightedComment: 0,
  submitOnEnter: true,
  handleSubmitOnEnterChange: () => { },
}

ForumComment.propTypes = {
  comment: PropTypes.object,
  connected: PropTypes.bool,
  closed: PropTypes.bool,
  highlightedComment: PropTypes.number,
  submitOnEnter: PropTypes.bool,
  handleSubmitOnEnterChange: PropTypes.func,
}

export default ForumComment
