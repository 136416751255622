import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import ResourceLink from "./ResourceLink";
import ResourceLinkForm from "./ResourceLinkForm";

class ResourceLinks extends Component {

  // React setup
  constructor(props) {
    super(props)
    this.state = {
      showForm: false,
      resourceLinks: []
    }
  }

  componentDidMount() {
    this.setState({ resourceLinks: this.sortResourceLinks(this.props.resource_links) })
    this.setupSubscription()
  }

  componentWillUnmount() {
    this.removeSubscription()
  }

  sortResourceLinks = (resourceLinks) => {
    return _.sortBy(resourceLinks, (resourceLink) => resourceLink.created_at)
  }

  // handlers
  toggleForm = (e) => {
    e.preventDefault()
    this.setState((state, props) => ({
      showForm: !this.state.showForm
    }))
  }

  updateResourceLinkList = (data) => {
    data = JSON.parse(data.resource_links)
    const resourceLinks = this.sortResourceLinks(data)
    this.setState({ resourceLinks: resourceLinks })
  }

  setupSubscription = () => {
    const _this = this
    App[`${this.props.linkable_type}:${this.props.linkable_id}':resource_links`] = App.cable.subscriptions.create({
      channel: "ResourceLinksChannel",
      linkable_type: this.props.linkable_type,
      linkable_id: this.props.linkable_id,
    }, {
        received: (data) =>
          _this.updateResourceLinkList(data)
      })
  }

  removeSubscription = () => {
    if (App[`${this.props.linkable_type}:${this.props.linkable_id}':resource_links`])
      App.cable.subscriptions.remove(App[`${this.props.linkable_type}:${this.props.linkable_id}':resource_links`])
  }

  render() {
    const formSection =
      this.state.showForm ?
        <ResourceLinkForm
          submission_url={this.props.submission_url}
          handleToggleForm={this.toggleForm}
        />
        : <button
          className="btn btn-primary btn-block mb-3"
          onClick={this.toggleForm}
        >
          <i className="fa-solid fa-plus mr-1"></i>
          Add Link
        </button>

    const resourceLinkList =
      this.state.resourceLinks.map(resource_link => (
        <ResourceLink
          key={resource_link.id}
          resource_link={resource_link}
          linkable_type={this.props.linkable_type}
          linkable_id={this.props.linkable_id}
          handleEditResourceLink={this.updateResourceLink}
        />
      ))

    return (
      <>
        {typeof gon != 'undefined' && ((this.props.linkable_type == "band" && gon.user.can_edit_bands) || (this.props.linkable_type == "contest" && gon.user.can_edit_contests)) ? formSection : null}

        {this.state.resourceLinks.length > 0 ? resourceLinkList : null}
      </>
    )
  }
}

ResourceLinks.defaultProps = {
  resource_links: [],
  linkable_type: "",
  linkable_id: 0,
  submission_url: "",
}

ResourceLinks.propTypes = {
  resource_links: PropTypes.arrayOf(PropTypes.object),
  linkable_type: PropTypes.string,
  linkable_id: PropTypes.number,
  submission_url: PropTypes.string,
}

export default ResourceLinks
