import React, { Component } from 'react'
import PropTypes from 'prop-types'
import ForumComment from './ForumComment'

class ForumComments extends Component {

  render() {
    return (
      <>
        {this.props.forumComments.map(comment => (
          <ForumComment
            key={comment.id}
            comment={comment}
            connected={this.props.connected}
            closed={this.props.closed}
            highlightedComment={this.props.highlightedComment}
            submitOnEnter={this.props.submitOnEnter}
            handleSubmitOnEnterChange={this.props.handleSubmitOnEnterChange}
          />
        ))}
      </>
    )
  }
}

ForumComments.defaultProps = {
  forumComments: [],
  connected: false,
  closed: false,
  highlightedComment: 0,
  submitOnEnter: true,
  handleSubmitOnEnterChange: () => { },
}

ForumComments.propTypes = {
  forumComments: PropTypes.arrayOf(PropTypes.object),
  connected: PropTypes.bool,
  closed: PropTypes.bool,
  highlightedComment: PropTypes.number,
  submitOnEnter: PropTypes.bool,
  handleSubmitOnEnterChange: PropTypes.func,
}

export default ForumComments
