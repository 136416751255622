import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { ReactionTypes } from '../ReactionTypes'
import { ModTag } from '../ModTag'
import UserLink from './UserLink'
import Reactions from './Reactions'
import ForumCommentForm from './ForumCommentForm'
import ForumComments from './ForumComments'
import ForumReportSubmission from './ForumReportSubmission'
import ax from 'utilities/axios'

class ForumPost extends Component {

  // React setup
  constructor(props) {
    super(props)
    this.state = {
      closed: false,
      edit: false,
      connected: false,
      showNewCommentForm: false,
      showReportSubmission: false,
      forumComments: [],
      submitOnEnter: false,
    }
  }

  componentDidMount() {
    this.setupSubscription()
    this.sortComments(this.props.forum_post.forum_comments)
    this.setState((state, props) => ({
      closed: props.forum_post['chain_closed?'] || props.forum_post.closed,
      submitOnEnter: gon.user.forum_submit_on_enter
    }))
  }

  sortComments = (comments) => {
    comments = _.sortBy(comments, ['created_at'])

    if (!gon.user.can_moderate_forum) {
      comments = _.filter(comments, (c) => (Boolean(c.hidden) === false))
    }

    this.setState({ forumComments: comments })
  }

  componentWillUnmount() {
    this.removeSubscription()
  }

  handleToggleNewCommentForm = (e) => {
    e.preventDefault()
    this.setState({ showNewCommentForm: !this.state.showNewCommentForm })
  }

  toggleReport = (e) => {
    e.preventDefault()
    this.setState({ showReportSubmission: !this.state.showReportSubmission })
  }

  handleSubmitOnEnterChange = (e) => {
    this.setState({ submitOnEnter: e.target.checked }, () => {
      ax.patch(`/users/preferences`, {
        user: {
          forum_submit_on_enter: this.state.submitOnEnter,
        }
      })
    })
  }

  addNewComment = (content) => {
    this.setState({ showNewCommentForm: false })
    App[`forum_post:${gon.forum_post_id}`].new_comment({ content: content, commentable_type: "ForumPost", commentable_id: gon.forum_post_id })
  }

  setupSubscription = () => {
    const _this = this
    App[`forum_post:${gon.forum_post_id}`] = App.cable.subscriptions.create({
      channel: "ForumPostChannel",
      forum_post: gon.forum_post_id,
    }, {

        connected() {
          // TODO this will fire after a reconnect occurs, but user could be missing some new info.  Should pull latest version.
          _this.setState({ connected: true })
        },

        disconnected() {
          _this.setState({ connected: false })
        },

        received(data) {
          _this.sortComments(JSON.parse(data.forum_post).forum_comments)

          _this.setState({ closed: JSON.parse(data.forum_post)['chain_closed?'] || JSON.parse(data.forum_post).closed })
        },

        new_comment(data) {
          this.perform("new_comment", { commentable_type: data.commentable_type, commentable_id: data.commentable_id, content: _.trim(data.content) })
        },

        react_comment(data) {
          this.perform("react_comment", { forum_comment_id: data.forum_comment_id, content: data.reaction })
        },

        hide_comment(data) {
          this.perform("hide_comment", { forum_comment_id: data.forum_comment_id, hidden: data.hidden })
        },

        update_comment(data) {
          this.perform("update_comment", { forum_comment_id: data.forum_comment_id, content: _.trim(data.content) })
        },

        destroy_comment(data) {
          this.perform("destroy_comment", { forum_comment_id: data.forum_comment_id })
        },
      }
    )
  }

  removeSubscription = () => {
    if (typeof App[`forum_post:${gon.forum_post_id}`] != 'undefined')
      App.cable.subscriptions.remove(App[`forum_post:${gon.forum_post_id}`])
  }

  render() {
    const reportDropdown =
      <div className="dropdown float-right">
        <a
          className="btn btn-link"
          href="#"
          data-toggle="dropdown"
          aria-haspopup="true"
          aria-expanded="false"
        >
          <i className='fa-solid fa-chevron-down hover-show-icon text-muted'></i>
        </a>
        <div className="dropdown-menu dropdown-menu-right">
          <a
            className="dropdown-item"
            href="#"
            onClick={this.toggleReport}
          >
            <i className='fa-solid fa-circle-exclamation mr-1'></i>
            {this.state.showReportSubmission ? "Cancel" : ""} Report
          </a>
        </div>

      </div>

    const editedTag =
      <p className="inline-with-dots">
        <span className="text-muted">Edited</span>
      </p>

    const commentButton =
      <div
        className="btn-group"
        role="group"
      >
        <a
          className="btn btn-primary"
          href="#"
          onClick={this.handleToggleNewCommentForm}
        >
          <i className="fa-solid fa-comment mr-1"></i>
          Comment
          </a>
      </div>


    const likeCommentToolbar =
      <div className="btn-toolbar">
        <div className="dropdown mr-1">
          <button
            className="btn btn-primary dropdown-toggle"
            href="#"
            type="button"
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
          >
            <i className="fa-solid fa-thumbs-up mr-1"></i>
            Like
          </button>
          <div
            className="dropdown-menu"
            key="reactions"
          >
            {ReactionTypes.map(reaction => (
              <a
                className="dropdown-item"
                key={reaction.text}
                href={`${this.props.forum_post.html_url}/react?reaction=${reaction.text}`}
              >
                <i className={`forum-reaction-icon-circle ${reaction.icon} mr-1`}></i>
                {reaction.label}
              </a>
            ))}
          </div>
        </div>
        {(!this.state.closed) ? commentButton : null}
      </div>

    const commentsClosed = <div className="well text-muted">A moderator has closed comments on this post.</div>

    const commentForm =
      <ForumCommentForm
        user={gon.user}
        handleNewComment={this.addNewComment}
        handleCancel={this.handleToggleNewCommentForm}
        connected={this.state.connected}
        submitOnEnter={this.state.submitOnEnter}
        handleSubmitOnEnterChange={this.handleSubmitOnEnterChange}
      />


    return (
      <section>
        <div className="container">
          <div className="row">
            <div className="col-12 col-md-10 col-lg-8">
              {reportDropdown}
              <div className="media">
                <div className="mr-2">
                  <div
                    className="color-box lg float-right"
                    style={{ backgroundColor: this.props.forum_post.user.profile_color }}
                  >
                    <span className="initials">{this.props.forum_post.user.initials}</span>
                  </div>
                </div>
                <div className="media-body">
                  <h2 className="mt-0">{this.props.forum_post.name}</h2>
                  <p className="lead">
                    {this.props.forum_post.user.display_name}
                    {(typeof gon.user != 'undefined' && gon.user.can_edit_users ? <UserLink user_url={this.props.forum_post.user.html_url} /> : null)}
                    {this.props.forum_post.user.can_moderate_forum ? ModTag : null}
                  </p>

                </div>
              </div>
              <p className="inline-with-dots">
                <span className="text-muted">
                  {moment(this.props.forum_post.created_at).format("MMM D, YYYY h:mm A")}
                </span>
              </p>
              {this.props.forum_post['edited?'] ? editedTag : null}
              <blockquote className="blockquote">
                {this.props.forum_post.content.split('\n').map((line, i) => (
                  <span key={i}>{line}<br /></span>
                ))}
              </blockquote>
              {this.state.showReportSubmission ?
                <ForumReportSubmission
                  reportableType="ForumPost"
                  reportableId={this.props.forum_post.id}
                  cancelReport={this.toggleReport}
                /> : null
              }
            </div>
          </div>
          <div className="row">
            <div className="col-12 col-md-10 col-lg-8">
              <Reactions reactions={this.props.forum_post.reactions} />
              {/* new line */}
              <div></div>
              {typeof gon.user != 'undefined?' ? likeCommentToolbar : null}
            </div>
          </div>
          <div className="row">
            <div className="col-12 col-md-10 col-lg-8">
              <h3 className="mt-4">Comments</h3>
              <ForumComments
                forumComments={this.state.forumComments}
                connected={this.state.connected}
                closed={this.state.closed}
                highlightedComment={this.props.highlighted_comment}
                submitOnEnter={this.state.submitOnEnter}
                handleSubmitOnEnterChange={this.handleSubmitOnEnterChange}
              />
              {this.state.closed ? commentsClosed : this.state.showNewCommentForm ? commentForm : null}
            </div>
          </div>
        </div>
      </section>
    )
  }
}

ForumPost.defaultProps = {
  forum_post: {},
  highlighted_comment: 0,
}

ForumPost.propTypes = {
  forum_post: PropTypes.object,
  highlighted_comment: PropTypes.number,
}

export default ForumPost
