import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import ax from 'utilities/axios'

class ShowStaffMember extends Component {

  // React setup
  constructor(props) {
    super(props)
    this.state = {
      staffMemberFullName: `${this.props.show_staff_member.staff_member.first_name} ${this.props.show_staff_member.staff_member.last_name}`,
      showOptions: false,
      removing: false,
    }
  }

  // handlers
  toggleOptions = (e) => {
    if (typeof gon != 'undefined' && gon.user.can_edit_bands) {
      e.preventDefault()
      this.setState({ showOptions: !this.state.showOptions })
    }
  }

  removeStaffMember = (e) => {
    e.preventDefault()
    this.setState({ removing: true })
    ax.delete(`/bands/${this.props.band_slug}/shows/${this.props.show_slug}/show_staff_members/${this.props.show_staff_member.id}`)
      .catch(error => {
        alert("An error occurred while removing this staff member. Please try again later.")
        this.setState({ removing: false })
      })
  }

  render() {
    const changeLogLink =
      <button
        className="btn btn-primary dropdown-toggle dropdown-toggle-split"
        key="changeLogLink"
        data-toggle="dropdown"
        aria-haspopup="true"
        aria-expanded="false"
      >
        <span className="sr-only">Toggle dropdown</span>
      </button>

    const changeLogDropdown =
      <div
        className="dropdown-menu"
        key="changeLogDropdown"
      >
        <a
          href={`/staff_members/${this.props.show_staff_member.staff_member.id}`}
          className="dropdown-item"
        >
          View Staff Member
          </a>
        <a
          href={`/bands/${this.props.band_slug}/shows/${this.props.show_slug}/show_staff_members/${this.props.show_staff_member.id}`}
          className="dropdown-item"
        >
          View Role History
          </a>
      </div>

    const optionsToolbar =
      <tr>
        <td colSpan="2">
          <div
            className="btn-toolbar d-flex"
            role="toolbar"
            aria-label="Staff Member actions"
          >
            <div className="btn-group btn-group-sm flex-fill mr-1">
              <a
                href={`/staff_members/${this.props.show_staff_member.staff_member.id}`}
                className="btn btn-primary"
              >
                <i className="fa-solid fa-eye mr-1"></i>
                View
              </a>

              {typeof gon != 'undefined' && gon.user.can_view_change_log ? [changeLogLink, changeLogDropdown] : null}

            </div>
            <a
              href={`/bands/${this.props.band_slug}/shows/${this.props.show_slug}/show_staff_members/${this.props.show_staff_member.id}/edit`}
              className="btn btn-warning btn-sm mr-1 flex-fill">
              <i className="fa-solid fa-pencil mr-1"></i>
              Edit
              </a>
            <button
              onClick={this.removeStaffMember}
              className="btn btn-danger btn-sm mr-1 flex-fill"
              disabled={this.state.removing}
              data-confirm="Are you sure you want to remove this staff member?"
            >
              {this.state.removing ? <i className="fa-solid fa-spinner fa-spin mr-1"></i> : null}
              <i className="fa-solid fa-trash mr-1"></i>
              Remove
              </button>
            <button
              onClick={this.toggleOptions}
              className="btn btn-secondary btn-sm mr-1 flex-fill"
            >
              <i className="fa-solid fa-xmark mr-1"></i>
              Close
              </button>
          </div>
        </td>
      </tr>

    const staffRow =
      <tr>
        <td>
          <strong>
            <a
              href={`/staff_members/${this.props.show_staff_member.staff_member.id}`}
              onClick={this.toggleOptions}
            >
              {this.state.staffMemberFullName}
            </a>
          </strong>
        </td>
        <td>
          {this.props.show_staff_member.role}
        </td>
      </tr>


    return (
      <Fragment>
        {this.state.showOptions ? optionsToolbar : staffRow}
      </Fragment>
    )
  }
}

ShowStaffMember.defaultProps = {
  band_slug: '',
  show_slug: '',
  show_staff_member: {},
}

ShowStaffMember.propTypes = {
  band_slug: PropTypes.string,
  show_slug: PropTypes.string,
  show_staff_member: PropTypes.object,
}

export default ShowStaffMember
