import React, { Component } from 'react'
import PropTypes from 'prop-types'
import ax from 'utilities/axios'

class CaptionAward extends Component {

  // React setup
  constructor(props) {
    super(props)
    this.state = {
      showOptions: false,
      removing: false,
    }
  }

  // handlers
  toggleOptions = (e) => {
    e.preventDefault()
    this.setState({ showOptions: !this.state.showOptions })
  }

  handleRemoveCaptionAward = (e) => {
    e.preventDefault()
    this.setState({ removing: true })
    ax.delete(`/contests/${this.props.contest_slug}/caption_awards/${this.props.caption_award.id}`)
      .then(data => {
        this.props.handleRemoveCaptionAward(this.props.caption_award.id)
      })
      .catch(error => {
        alert("An error occurred while removing this caption award. Please try again later.")
        this.setState({ removing: false })
      })
  }

  render() {
    const removeLink =
      <a
        href="#"
        onClick={this.handleRemoveCaptionAward}
        className="ml-1"
        disabled={this.state.removing}
        data-confirm="Are you sure you want to remove this caption award?"
      >
        {this.state.removing ? <i className="fa-solid fa-spinner fa-spin mr-1"></i> : null}
        <i className="fa-solid fa-trash"></i>
      </a>

    return (
      <li className="list-group-item">
        {this.props.caption_award.name}
        <span className="float-right">
          <a
            href={`/contests/${this.props.contest_slug}/caption_awards/${this.props.caption_award.id}/edit`}
          >
            <i className="fa-solid fa-pencil"></i>
          </a>
          {this.props.caption_award.performances.length == 0 ? removeLink : null}
        </span>
      </li>
    )
  }
}

CaptionAward.defaultProps = {
  band_slug: '',
  show_slug: '',
  caption_award: {},
  handleRemoveCaptionAward: () => { },
}

CaptionAward.propTypes = {
  band_slug: PropTypes.string,
  show_slug: PropTypes.string,
  caption_award: PropTypes.object,
  handleRemoveCaptionAward: PropTypes.func,
}

export default CaptionAward
