import React, { Component } from 'react'
import PropTypes from 'prop-types'

class ForumNotification extends Component {

  // React setup
  constructor(props) {
    super(props)
  }

  componentDidMount() {
    $('[data-toggle="tooltip"]').tooltip()
  }

  markRead = () => {
    App['forum_notifications:' + gon.user.id].markNotificationRead({ forum_notification_id: this.props.notification.id })
  }

  handleView = (e) => {
    this.markRead()
  }

  handleRead = (e) => {
    e.preventDefault();
    this.markRead()
  }

  render() {
    const markReadButton =
      <a
        className="rIcon"
        href="#"
        onClick={this.handleRead}
      >
        <i className="fa-regular fa-circle-dot"></i>
      </a>

    return (
      <div className="row">
        <div className="col-2">
          <div className="notify-img">
            <div
                className="color-box"
                style={{ backgroundColor: this.props.notification.user.profile_color }}
              >
              <span className="initials">{this.props.notification.user.initials}</span>
            </div>
          </div>
        </div>
        <div className="col-8">
          <a
            onClick={this.handleView}
            href={(this.props.notification.notifiable_type == "ForumComment") ? `${this.props.notification.forum_post_url}?comment=${this.props.notification.notifiable_id}` : this.props.notification.forum_post_url}
          >
            {this.props.notification.message}
          </a>
          <hr />
          <p className="time">{moment(this.props.notification.created_at).fromNow()}</p>
        </div>
        <div className="col-1">
          {this.props.notification.read ? null : markReadButton}
        </div>
      </div>
    )
  }
}

ForumNotification.defaultProps = {
  notification: {}
}

ForumNotification.propTypes = {
  notification: PropTypes.object
}

export default ForumNotification
