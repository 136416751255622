import React, { Component } from 'react'
import PropTypes from 'prop-types'
import ax from 'utilities/axios'

class ShowStaffMemberForm extends Component {

  // React setup
  constructor(props) {
    super(props)
    this.state = {
      staffMemberAddError: false,
      staff_member_first_name: '',
      staff_member_last_name: '',
      role: '',
      submitting: false
    }
    this.firstNameInput = React.createRef();
    this.form = React.createRef();
  }

  componentDidMount() {
    this.form.current.addEventListener("keydown", this.handleKeyPress, false)
  }

  componentWillUnmount() {
    this.form.current.removeEventListener("keydown", this.handleKeyPress, false)
  }

  // handlers
  handleChange = (e) => {
    const name = e.target.name
    this.setState({ [`${name}`]: e.target.value })
  }

  handleKeyPress = (e) => {
    if (e.keyCode == 27) { // esc
      e.preventDefault()
      this.handleCancel(e)
    }
  }

  handleCancel = (e) => {
    e.preventDefault()
    this.props.handleToggleNewShowStaffMemberForm(e)
  }

  handleSubmit = (e) => {
    e.preventDefault()
    this.setState({ submitting: true })
    ax.post(`/bands/${this.props.band_slug}/shows/${this.props.show_slug}/show_staff_members`,
      {
        show_staff_member: {
          staff_member_name: {
            first_name: _.trim(this.state.staff_member_first_name),
            last_name: _.trim(this.state.staff_member_last_name)
          },
          role: _.trim(this.state.role)
        },
      }
    )
      .then(data => {
        this.firstNameInput.current.focus()
        this.setState({
          staff_member_first_name: '',
          staff_member_last_name: '',
          role: '',
          staffMemberAddError: false,
          submitting: false,
        })
      })
      .catch(error => {
        this.setState({
          staffMemberAddError: true,
          submitting: false,
        })
      })
  }

  render() {
    return (
      <div className="card">
        <div className="card-body">
          <form
            ref={this.form}
            onSubmit={this.handleSubmit}
          >
            <div className="form-group">
              <input
                type="text"
                className="form-control mb-2"
                name="staff_member_first_name"
                onChange={this.handleChange}
                placeholder="First Name"
                ref={this.firstNameInput}
                autoFocus={true}
                value={this.state.staff_member_first_name}
              />
              <input
                type="text"
                className="form-control mb-2"
                name="staff_member_last_name"
                onChange={this.handleChange}
                placeholder="Last Name"
                value={this.state.staff_member_last_name}
              />
              <input
                type="text"
                className="form-control mb-2"
                name="role"
                onChange={this.handleChange}
                placeholder="Role"
                value={this.state.role}
              />
            </div>
            {this.state.staffMemberAddError ? <div className="alert alert-danger" role="alert">An error occurred. Please try again.</div> : null}
            <button
              type="submit"
              className="btn btn-primary btn-block"
              disabled={this.state.staff_member_first_name.length === 0 || this.state.submitting}
            >
              {this.state.submitting ? <i className="fa-solid fa-spinner fa-spin mr-1"></i> : null}
              Add Staff Member
            </button>
            <button
              className="btn btn-link btn-block"
              onClick={this.handleCancel}
            >
              Cancel
            </button>
          </form>
        </div>
      </div>
    )
  }
}

ShowStaffMemberForm.defaultProps = {
  band_slug: '',
  show_slug: '',
  handleToggleNewShowStaffMemberForm: () => { },
}

ShowStaffMemberForm.propTypes = {
  band_slug: PropTypes.string,
  show_slug: PropTypes.string,
  handleToggleNewShowStaffMemberForm: PropTypes.func,
}

export default ShowStaffMemberForm
